import { pageSettingsType } from 'types/pages/pageContainer.types';
import { advisorsPageType } from 'humanity/pages/advisorsPage/advisorsPage.types';
import AdvisorsPage from 'humanity/pages/advisorsPage';
import { getAdvisorsPageSSG } from 'humanity/pages/advisorsPage/advisorsPage.query';

const Page = ({ pageSettings, pageContent }) => (
  <AdvisorsPage pageSettings={pageSettings} pageContent={pageContent} />
);

Page.propTypes = {
  pageSettings: pageSettingsType.isRequired,
  pageContent: advisorsPageType.isRequired,
};

export const getStaticProps = getAdvisorsPageSSG('hia/index');

export default Page;
